<template>
  <div>
    <b-row>
      <b-col :md="12">
        <b-card title="Mahsulotni naqdga sotishni rasmiylashtirish">
          <b-overlay :show="form_loading">
            <validation-observer
              ref="form"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <b-button
                  v-if="!show_add_product"
                  variant="outline-primary"
                  class="btn-tour-skip mb-2"
                  @click="addProductShow"
                >
                  <span class="mr-25 align-middle">
                    <feather-icon icon="PlusIcon" />
                    {{ $t("Добавить продукт") }}</span>
                </b-button>

                <div v-else>
                  <div
                    v-for="(product, pIndex) in form.products"
                    :key="pIndex"
                  >
                    <div
                      v-if="form.products.length == pIndex + 1"
                      class="row"
                    >
                      <b-form-group
                        :label="$t('Код товара')"
                        label-for="barcode"
                        class="col-6"
                      >
                        <b-overlay :show="barcodeInputLoading">
                          <b-form-input
                            id="barcode"
                            ref="barcode"
                            v-model="product.barcode"
                            name="barcode"
                            placeholder="000000000000"
                            maxlength="12"
                            @input="
                              getProductByBarcode(product.barcode, pIndex)
                            "
                          />
                        </b-overlay>
                      </b-form-group>

                      <div class="col-2">
                        <span class="fs20">
                          <feather-icon
                            class="mt-2"
                            color="#FF0000"
                            icon="XIcon"
                            @click="show_add_product = false"
                          />
                        </span>
                      </div>

                      <b-form-group
                        :label="$t('Aksiya')"
                        label-for="barcode"
                        class="col-4"
                      >
                        <b-form-checkbox
                          v-model="form.aksiya"
                          name="check-button"
                          :value="1"
                          :unchecked-value="0"
                          inline
                        >
                          Aksiyada sotish
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div v-if="form.products && form.products[0].id">
                  <table class="table table-hover table-responsive">
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>Nomi</th>
                        <th>Soni</th>
                        <th>Narxi</th>
                        <th>Jami</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(product, index) in form.products">
                        <tr
                          v-if="product.id"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td
                            v-if="product.id"
                            class="text-primary"
                            @click="editProduct(index)"
                          >
                            {{ product.name }}

                            <div v-if="hasMobileProduct">
                              <p v-if="product.imei_1">
                                <b>IMEI 1: </b> {{ product.imei_1 }}
                              </p>
                              <p v-if="product.imei_2">
                                <b>IMEI 2: </b> {{ product.imei_2 }}
                              </p>
                            </div>
                          </td>
                          <td>{{ product.quantity }} dona</td>

                          <td
                            v-if="product.id"
                            class="text-nowrap"
                          >
                            <span>
                              {{ product.price | formatPrice }}so'm
                            </span>

                            <div class="font-weight-bolder mt-1 w-100">
                              <b-form-group
                                v-if="form.aksiya == 1"
                                label="Aksiya narxi"
                              >
                                <cleave
                                  v-model="product.real_price"
                                  class="form-control"
                                  :options="cleaveOption"
                                  placeholder="0"
                                  @input="changeSalePrice(product)"
                                />
                              </b-form-group>
                            </div>

                          </td>
                          <td
                            v-if="product.id"
                            class="text-nowrap"
                          >
                            <p :class="{ textStrike: form.aksiya == 1 }">
                              {{
                                (product.quantity * product.price) | formatPrice
                              }}so'm
                            </p>
                            <p v-if="form.aksiya == 1">
                              {{
                                (product.quantity * Number(product.real_price)) | formatPrice
                              }}so'm
                            </p>

                          </td>
                          <td>
                            <feather-icon
                              color="#FF0000"
                              icon="TrashIcon"
                              @click="deleteProduct(index)"
                            />
                          </td>
                        </tr>
                      </template>
                      <tr>
                        <th colspan="2">
                          Jami
                        </th>
                        <th colspan="4">
                          {{ getTotalProduct() | formatPrice }}so'm
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <div class="row">
                    <div class="col-6">
                      <b-form-group
                        :label="$t('ФИО')"
                        label-for="bought_for_cash_client_full_name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('bought_for_cash_client_full_name')"
                          rules="required"
                        >
                          <b-form-input
                            id="bought_for_cash_client_full_name"
                            v-model="form.bought_for_cash_client_full_name"
                            :state="errors.length > 3 ? false : null"
                            name="bought_for_cash_client_full_name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group
                        :label="$t('Введите телефон')"
                        label-for="v-phone"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Введите телефон')"
                          rules="required"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              +998
                            </b-input-group-prepend>

                            <b-form-input
                              id="v-phone"
                              v-model="form.bought_for_cash_client_phone"
                              v-mask="'#########'"
                              name="v-phone"
                              :state="errors.length > 3 ? false : null"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-form-textarea
                        id="comment"
                        v-model="form.comment"
                        class="form-control"
                        placeholder="Комментарий"
                        rows="3"
                      />
                    </div>
                  </div>
                </div>

                <!--  -->
                <div class="text-right mt-3">
                  <b-button
                    variant="primary"
                    class="btn-tour-skip"
                    type="submit"
                    :disabled="isWrongSale"
                    @click="save()"
                  >
                    <span class="mr-25 align-middle">Sotish</span>
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col :md="12">
        <ProductCashTable ref="cashtable" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { mapActions, mapGetters } from 'vuex'
import 'vue-tree-halower/dist/halower-tree.min.css'
import { required } from '@validations'
import ProductCashTable from '@/components/tables/productCashs.vue'
import Cleave from 'vue-cleave-component'
import { debounce } from 'debounce'
import { getItem } from '@/utils/storage'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Create',
  components: {
    ValidationObserver,
    ValidationProvider,
    ProductCashTable,
    Cleave,
  },

  data() {
    return {
      show_add_product: false,
      barcodeInputLoading: false,
      contracts: null,
      isWrongSale: false,
      required,
      form: {
        products: [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            imei_1: null,
            imei_2: null,
            name: '',
          },
        ],
        // showProducts: [
        //   {
        //     id: null,
        //     category_id: null,
        //     price: null,
        //     quantity: 1,
        //     imei_1: null,
        //     imei_2: null,
        //     name: '',
        //   },
        // ],
        aksiya: 0,
        amount: null,
        bought_for_cash_client_phone: null,
        bought_for_cash_client_full_name: null,
      },
      loading: false,
      form_loading: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
    }
  },
  computed: {
    ...mapGetters({
      products: 'product/GET_ITEMS',
      clientReport: 'resource/CLIENT_REPORT',
      settings: 'setting/GET_ITEMS',
      client2Report: 'resource/CLIENT2_REPORT',
    }),
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    hasMobileProduct() {
      return this.form.products.some(e => e.imei_1)
    },
    today() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const todays = today.toLocaleDateString()
      if (todays.search('/') > 0) {
        return `${todays.split('/')[2]}-${todays.split('/')[0]}-${
          todays.split('/')[1]
        }`
      }
      return todays.split('.').reverse().join('-')
    },
    dollarRate() {
      const rate = getItem('realDollarRate')
      return Number(rate)
    },
  },
  mounted() {
    this.form.start_date = this.today
    this.getProductsAction()
    // this.paymentsList()
    this.addProductShow()
  },
  methods: {
    paymentsList() {
      this.getPaymentsAction({
        per_page: 50,
        type: 3,
        relations: 'createdUser',
      }).then(res => {
        this.contracts = res.data
      })
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async save() {
      const valid = await this.validationForm()
      this.clearProductsArray()
      if (valid) {
        this.form_loading = true
        this.store(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.clearFormFields()
            this.$refs.cashtable.getItems()
            this.form_loading = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
            this.form_loading = false
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    debounce(fn, delay) {
      let timer
      return (() => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), delay)
      })()
    },
    clearFormFields() {
      this.form.amount = null
      this.form.bought_for_cash_client_phone = null
      this.form.bought_for_cash_client_full_name = null
      this.form.products = [
        {
          id: null,
          category_id: null,
          price: null,
          quantity: 1,
          imei_1: null,
          imei_2: null,
          name: '',
          real_price: null,
          cost: null,
          cost_dollar: null,
        },
      ]
    },
    addProduct() {
      this.form.products.push({
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        imei_1: null,
        imei_2: null,
        name: '',
        real_price: null,
        cost: null,
        cost_dollar: null,
      })
    },
    deleteProduct(index) {
      this.clearProductsArray()
      setTimeout(() => {
        if (this.form.products.length == 1) {
          this.form.products = [
            {
              id: null,
              category_id: null,
              price: null,
              quantity: 1,
              imei_1: null,
              imei_2: null,
              name: '',
              real_price: null,
              cost: null,
              cost_dollar: null,
            },
          ]
        } else {
          this.form.products.splice(index, 1)
        }
      }, 500)
    },

    getTotalProduct() {
      let total = 0

      if (this.form.aksiya == 1) {
        this.form.products.forEach(el => {
          total += el.quantity * Number(el.real_price)
        })
      } else {
        this.form.products.forEach(el => {
          total += el.quantity * el.price
        })
      }
      this.form.amount = total
      return total
    },
    addProductShow() {
      this.show_add_product = true
      if (
        this.form.products[this.form.products.length - 1].barcode
        && this.form.products[this.form.products.length - 1].barcode.length == 12
      ) {
        this.addProduct()
      }
      setTimeout(() => {
        this.$refs.barcode[this.form.products.length - 1].focus()
      }, 100)
    },
    getProductByBarcode(barcode, index) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true
        const foundProduct = this.form.products.find(
          el => el.barcode == barcode,
        )
        let current_quantity = 0
        if (foundProduct && foundProduct.id) {
          current_quantity = foundProduct.quantity
        }
        this.getWarehouseProducts({ barcode, current_quantity })
          .then(res => {
            const product = this.form.products[index]
            console.log(barcode, index, product)
            const hasProduct = this.form.products.find(
              el => el.id === res.data.id,
            )
            const hasProductIndex = hasProduct
              ? this.form.products.indexOf(hasProduct)
              : null
            if (
              hasProduct
              && this.form.products.length > 1
              && this.form.products.length - 1 == index
            ) {
              this.form.products[hasProductIndex].quantity += 1
            } else if (product && this.form.products.length - 1 == index) {
              product.id = res.data.id
              product.category_id = res.data.category_id
              product.name = res.data.category.name_uz
              product.imei_1 = res.data.imei_1
              product.imei_2 = res.data.imei_2
              product.name = res.data.category.name_uz
              product.price = res.data.price ? res.data.price : res.data.cost
              product.real_price = res.data.price ? res.data.price : res.data.cost
              product.cost = res.data.cost
              product.cost_dollar = res.data.cost_dollar
            }
            if (this.form.products.length - 1 == index) {
              this.addProduct()
            }
          })
          .finally(() => {
            this.barcodeInputLoading = false
            setTimeout(() => {
              this.$refs.barcode[0].focus()
            }, 100)
          })
      } else if (barcode && barcode.length > 12) {
        this.form.products[index].barcode = Number(
          String(barcode).slice(0, 12),
        )
      }
    },
    changeSalePrice(product) {
      const valid = this.validationForm()
      console.log(valid)
      if (Number(product.real_price) < Number(this.dollarRate) * Number(product.cost_dollar)) {
        this.isWrongSale = true
      } else {
        this.isWrongSale = false
      }
      this.checkSalePrice(product, this.dollarRate)
    },
    checkSalePrice: debounce((product, rate) => {
      if (Number(product.real_price) < Number(rate) * Number(product.cost_dollar)) {
        showToast('warning', "Mahsulot narxi asl narxidan kam bo'lishi mumkin emas")
      }
    }, 1000),
    clearProductsArray() {
      if (this.form.products.length > 1) {
        this.form.products = this.form.products.filter(el => el.id != null)
      }
      // if (this.form.showProducts.length > 1) {
      //   this.form.showProducts = this.form.showProducts.filter(
      //     el => el.id != null,
      //   )
      // }
    },
    ...mapActions({
      store: 'contract/sellForCash',
      getProductsAction: 'product/index',
      getItemsAction: 'productCategory/tree',
      getWarehouseProducts: 'productCategory/warehouseProductsByBarcode',
      fileUpload: 'client/fileUpload',
      fetchSettings: 'setting/index',
      getPaymentsAction: 'contract/paymentList',
      calculateCreditAmount: 'resource/calculateCreditAmount',
    }),
  },
}
</script>

<style scoped></style>
